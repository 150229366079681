import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  CREATE,
  UPDATE,
  DELETE,
  GET_MANY_REFERENCE,
  fetchUtils
} from 'react-admin';
import { get } from "lodash";

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */

let api = "https://orders.restaurantops.co/api/reseller";
// api = "http://localhost:3002/api/reseller";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};



const serialize = (obj = {}) =>  {
  return '?'+Object.keys(obj).reduce(function(a,k){a.push(k+'='+encodeURIComponent(obj[k]));return a},[]).join('&')
}

const restToSDKRequestConverter = (type, resource, params) => {
  let promise = Promise.resolve();
  switch (type) {
    case GET_LIST: {
      const {page, perPage} = params.pagination;
      const {field, order} = params.sort;
      const query = {
        ...params.filter,
        perPage,
        page
      };

      if (resource === "companies") {
        return httpClient(`${api}/${resource}`, query);
      }

      return httpClient(`${api}/companies/${localStorage.getItem("company_id")}/${resource}${serialize(query)}`);

      break;
    }
    case GET_ONE:
      if (resource === "companies") {
        return Promise.resolve({data: {id: params.id}});
      }
      break;
    case GET_MANY: {

      break;
    }
    case UPDATE:

      break;
    case CREATE:

      break;
    case DELETE:
      return httpClient(`${api}/companies/${localStorage.getItem("company_id")}/${resource}/${params.id}`, {method: 'DELETE'});
      break;
    case GET_MANY_REFERENCE:
      const {page, perPage} = params.pagination;
      const {field, order} = params.sort;
      const query = {
        ...params.filter,
        perPage,
        page
      };

      return httpClient(`${api}/companies/${params.company_id}/${resource}${serialize(query)}`, query);
      break;
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }

  return promise;
};

const responseToREST = (response, type, resource, params) => {
  switch (type) {
    case GET_ONE:
      return response;
    case GET_LIST:
      return {
        data: response.json.results,
        total: response.json.total_count
      };
    case GET_MANY_REFERENCE:
    case GET_MANY:
      return response.data;
    default:
      return response;
  }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} params Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a REST response
 */
export default (type, resource, params) => {
  return restToSDKRequestConverter(type, resource, params)
    .then(result => {
      return responseToREST(result, type, resource, params);
    });
};