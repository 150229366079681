import React from "react";
import { List, Datagrid, BooleanField, TextField } from "react-admin";

export const CompanyList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <BooleanField source="allow_line_item_notes" />
      <TextField source="contact_email" />
      <TextField source="delivery_fee" />
      <BooleanField source="enable_delivery" />
      <BooleanField source="enable_pick_up" />
      <BooleanField source="enable_pay_later" />
      <TextField source="minimum_delivery_amount" />
      <TextField source="name" />
      <TextField source="phone" />
      <TextField source="primary_color" />
      <BooleanField source="stripe_connected" />
      <TextField source="single_location.id" />
      <TextField source="id" />
    </Datagrid>
  </List>
);