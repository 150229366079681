import React from "react";
import { get } from "lodash";
import { List, Datagrid, BooleanField, TextField, DateField } from "react-admin";
import { withRouter } from 'react-router'
import restClient from "../restClient";

const OrdersList = props => {
 const FieldButton = fieldprops =>
   <button color="danger" onClick={() => {
     let confirmed = window.confirm("Are you sure?");
     if(confirmed) {
      restClient("DELETE", "orders", fieldprops.record).then(() => {
        location.reload; // eslint-disable-line
      })
     }

   }}>Delete</button>


 return (
   <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="order_placed_at" />
      <TextField source="email" />
      <TextField source="phone" />
      <TextField source="total" />
      <BooleanField source="printed" />
      <FieldButton/>
    </Datagrid>
  </List>
 )
};

export default withRouter(OrdersList);

