import React from "react";
import { Button, Show, SimpleShowLayout, ListButton, RichTextField, NumberField } from "react-admin"
import { Link } from "react-router-dom";


export class CompaniesShow extends React.Component {
  componentDidMount() {
    localStorage.setItem("company_id", this.props.id);
  }

  render() {
    return (
      <Show {...this.props}>
        <SimpleShowLayout>
          <Button
            component={Link}
            to={{
              pathname: `/orders/${this.props.id}`,
              state: { company_id: this.props.id }
            }}
            label="View Orders"
          />

          <Button
            component={Link}
            to={{
              pathname: `/customers/${this.props.id}`,
              state: { company_id: this.props.id }
            }}
            label="View Customers"
          />
        </SimpleShowLayout>
      </Show>
    )
  }
}