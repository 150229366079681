import React from 'react';
import { Admin, Resource, ListGuesser } from 'react-admin';
import './App.css';
import authProvider from "./auth/authProvider";
import restClient from "./restClient";
import {CompanyList} from "./companies/CompaniesList";
import {CompaniesShow} from "./companies/CompaniesShow";

import OrdersList from "./orders/OrdersList";

const App = () =>
  <Admin dataProvider={restClient} authProvider={authProvider}>
    <Resource name="companies" list={CompanyList} show={CompaniesShow} />
    <Resource name="orders" list={OrdersList} />
    <Resource name="customers" list={ListGuesser} />
  </Admin>;


export default App;
